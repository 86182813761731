export const aboutSlide1 = require('./slide1.jpg?webp');
export const aboutSlide1x2 = require('./slide1-2x.jpg?webp');
export const aboutSlide2 = require('./slide2.jpg?webp');
export const aboutSlide2x2 = require('./slide2-2x.jpg?webp');
export const aboutSlide3 = require('./slide3.jpg?webp');
export const aboutSlide3x2 = require('./slide3-2x.jpg?webp');
export const aboutSlide4 = require('./slide4.jpg?webp');
export const aboutSlide4x2 = require('./slide4-2x.jpg?webp');
export const aboutSlide5 = require('./slide5.jpg?webp');
export const aboutSlide5x2 = require('./slide5-2x.jpg?webp');
export const aboutSlideEasiConnect = require('./slide-easiConnect.png?webp');
