import { PRODUCT_LIST_LOGISTIC, PRODUCT_LIST_UTILITIES, SERVICE_LINK_LIST } from 'config/routes/linkLists';
import React from 'react';
import { FacebookIcon, InstagramIcon, LinkedInIcon } from 'svgs';
import { LogisticsIcon, ProfessionalServicesIcon, UtilitiesIcon } from 'svgs/navbar';
import { INavLinks, TFooterIconLink, TNavLabelLinks } from 'templates/layout/types';

export const servicesLinks: TNavLabelLinks[] = [
  {
    label: 'Professional Services',
    icon: <ProfessionalServicesIcon />,
    links: SERVICE_LINK_LIST,
  },
];

export const productLinks: TNavLabelLinks[] = [
  {
    label: 'Websites',
    // icon: <UtilitiesIcon />,
    links: PRODUCT_LIST_UTILITIES,
  },
  {
    label: 'Products',
    // icon: <LogisticsIcon />,
    links: PRODUCT_LIST_LOGISTIC,
  },
];

export const followUsIconLinks: TFooterIconLink[] = [
  {
    logo: <InstagramIcon />,
    link: 'https://instagram.com/ussoftwaresolutions',
  },
  {
    logo: <FacebookIcon />,
    link: 'https://facebook.com/USSoftwareSolutions',
  },
  {
    logo: <LinkedInIcon />,
    link: 'https://linkedin.com/company/ussoftwaresolutions',
  },
];

export const navbarLinks: INavLinks[] = [
  {
    label: 'Home',
    route: '/',
  },
  {
    label: 'Services',
    links: servicesLinks,
  },
  {
    label: 'Our Work',
    links: productLinks,
  },
  {
    label: 'Company',
    route: '/company',
  },
  {
    label: 'Blog',
    route: '/blog',
  },
  {
    label: 'Careers',
    route: '/careers',
  },
];
