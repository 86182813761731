import { ImageDescription } from 'components/structures/general';
import { bgAboutUs, image1AboutUs, image1AboutUs2x, sectionForEc, slide4, slide5 } from 'images/main';
import { aboutSlide1, aboutSlide2, aboutSlide3, aboutSlide3x2, aboutSlideEasiConnect } from 'images/main/about-us';
import { NextPage } from 'next';
import React from 'react';
import { SEO } from 'templates/layout';
import { GetInTouch, OfficeLocations, OurApproach, OurProjects, PartnersPrompter, Slider } from 'templates/main';

const Main: NextPage = () => {
  return (
    <>
      <SEO
        title="Home"
        description="A software solutions company offering web development services, devising marketing strategy, and leveraging IT services for businesses and public organizations."
      />

      <Slider
        sliderList={[
          {
            upperTitle: 'Software Solution',
            title: 'Simpler, Smarter Tools for Internet Service Providers',
            image: sectionForEc,
            buttons: [
              { text: 'Contact Us', link: '/contact-us', variant: 'contained' },
              { text: 'Products', link: '/easiConnect', variant: 'outlined' },
            ],
          },
          {
            upperTitle: 'Telecommunications',
            title: 'Intricate Telecom software made simple',
            image: slide4,
            buttons: [
              { text: 'Contact Us', link: '/contact-us', variant: 'contained' },
              { text: 'Utilities One', link: '/utilities-one', variant: 'outlined' },
            ],
          },
          {
            upperTitle: 'Redefining Logistics',
            title: 'Helping move freight the right way',
            image: slide5,
            buttons: [
              { text: 'Contact Us', link: '/contact-us', variant: 'contained' },
              { text: 'Patron Trucking', link: '/patron-trucking', variant: 'outlined' },
            ],
          },
        ]}
      />

      <OurApproach subTitle={'How we do it?'} title={'Our Approach'} button={'Our Services'} />

      <ImageDescription
        image={{ image: aboutSlide1, srcSet: `${image1AboutUs} 1x, ${image1AboutUs2x} 2x,` }}
        bgImage={{ image: bgAboutUs }}
        upperTitle="About Us"
        title="Discover US Software Solutions"
        text="US Software Solutions offers a full range of IT services focused on delivering high-end digital solutions for businesses and public organizations in the Energy sector. Thanks to a team consisting exclusively of experts in their field, we are ready to supervise any project, regardless of its complexity and scale."
        link={{ text: 'Company', href: '/company' }}
      />

      <OurProjects
        sliderList={[
          {
            title: { text: 'EasiConnect', colorLeft: '#172D62', colorRight: '#172D62' },
            image: { image: aboutSlideEasiConnect, srcSet: `${aboutSlideEasiConnect} 1x, ${aboutSlideEasiConnect} 2x,` },
            link: '/easiConnect',
          },
          {
            title: { text: 'Patron Trucking', colorLeft: '#006DC1', colorRight: '#3B94DA' },
            image: { image: aboutSlide2, srcSet: `${aboutSlide2} 1x, ${aboutSlide2} 2x,` },
            link: '/patron-trucking',
          },
          {
            title: { text: 'Utilities One', colorLeft: '#D74136', colorRight: '#F58922' },
            image: { image: aboutSlide3, srcSet: `${aboutSlide3} 1x, ${aboutSlide3x2} 2x,` },
            link: '/utilities-one',
          },
        ]}
      />
      {/* TODO Uncomment if this section is needed */}
      {/* <StatisticsSection
        statistics={[
          {
            numberValue: 97,
            text: 'Test Coverage',
            suffix: '%',
          },
          {
            numberValue: 126720,
            text: 'Development Hours',
          },
          {
            numberValue: 5,
            text: 'Years Hard Work',
          },
          {
            numberValue: 30,
            text: 'Our Partners',
            prefix: '+',
          },
        ]}
      /> */}

      <PartnersPrompter />
      <OfficeLocations />
      <GetInTouch
        title="Get in touch"
        text="We're just a few clicks away! Feel free to contact us by clicking below."
        link={{ text: 'Contact Us', href: '/contact-us' }}
      />
    </>
  );
};

export default Main;
