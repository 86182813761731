import { Box, Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { bucuresti, chicago, chisinau, mapBg, newJersey } from 'images/main';
import React, { FC } from 'react';

const cardLinks: LocationCardProps[] = [
  {
    title: 'Chisinau',
    image: chisinau,
    link: 'https://g.page/us-software-solutions',
    address: 'Columna 104/1, MD-2005, Republic of Moldova',
  },
  {
    title: 'Bucharest',
    image: bucuresti,
    link: 'https://maps.app.goo.gl/1gPNB8mFhxMv3FoJA',
    address: 'Popa Tatu 62A, Sector 1, Romania',
  },
  {
    title: 'New Jersey',
    image: newJersey,
    link: 'https://goo.gl/maps/HyP89napy4ZuPW6U6',
    address: '300 W Somerdale Rd, Suite 5, Voorhees Township, NJ 08043',
  },
  {
    title: 'Chicago, IL',
    image: chicago,
    link: 'https://goo.gl/maps/MivKUskJZnLuZMjA7',
    address: '1051 Perimeter Dr., Suite 510 Schaumburg, IL 60 173',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(0, 0, 10),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 0, 15),
    },
  },
  title: {
    color: theme.palette.primary.light,
  },
  text: {
    maxWidth: 600,
    color: theme.palette.switchedColors.frameTitle,
    margin: theme.spacing(0, 'auto', 10),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 'auto', 20),
    },
  },
  mapWrapper: {
    minHeight: 800,
    padding: theme.spacing(8, 0),
  },
  cardBox: {
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    backgroundColor: theme.palette.switchedColors.simpleCard,
    display: 'flex',
    borderRadius: theme.spacing(4),
    maxWidth: 440,
    margin: theme.spacing(0, 'auto', 6),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 'auto', 23),
    },
  },
  cardImage: {
    borderRadius: theme.spacing(4),
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    maxHeight: 112,
    maxWidth: 116,
  },
  address: {
    fontSize: 14,
    marginTop: theme.spacing(1),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  mapBg: {
    backgroundImage: `url(${mapBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '20% 100%',
  },
}));

const OfficeLocations: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <Container className={classes.mapBg}>
        <Typography className={classes.title} variant="h2" align="center">
          Get to know us
        </Typography>
        <Box className={classes.mapWrapper}>
          <Typography className={classes.text} align="center">
            Our software solutions enable our customers to significantly simplify access to services provided and make their new
            business solutions even more attractive to their clients.
          </Typography>
          <Grid container>
            {cardLinks.map((props, index) => (
              <LocationCard {...props} key={index} />
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

interface LocationCardProps {
  image: string;
  title: string;
  address: string;
  link: string;
}

const LocationCard: FC<LocationCardProps> = ({ address: address, image, link, title }) => {
  const classes = useStyles();
  return (
    <Grid item md={6} xs={12}>
      <Box className={classes.cardBox}>
        <img className={classes.cardImage} src={image} alt="city" />
        <Box ml={3} py={3} maxWidth={260}>
          <Typography variant="h4">{title}</Typography>
          <Typography
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            variant="body2"
            className={classes.address}
          >
            {address}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default OfficeLocations;
